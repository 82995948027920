// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\templates\\BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-development-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-index-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leap-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\leap.js" /* webpackChunkName: "component---src-pages-leap-js" */),
  "component---src-pages-marketing-technology-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\marketing-technology.js" /* webpackChunkName: "component---src-pages-marketing-technology-js" */),
  "component---src-pages-privacy-policy-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-strategic-consulting-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\strategic-consulting.js" /* webpackChunkName: "component---src-pages-strategic-consulting-js" */),
  "component---src-pages-why-smartempo-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\why-smartempo.js" /* webpackChunkName: "component---src-pages-why-smartempo-js" */),
  "component---src-pages-work-management-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\work-management.js" /* webpackChunkName: "component---src-pages-work-management-js" */),
  "component---src-pages-workfront-js": () => import("D:\\backup\\documentos otros\\Smartempo\\stpo-gatsby-site\\src\\pages\\workfront.js" /* webpackChunkName: "component---src-pages-workfront-js" */)
}

